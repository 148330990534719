.Row{
    border-bottom: 1px solid #0000002f;
    @apply flex box-border 
     w-full 
    h-16 justify-evenly p-2 py-4 items-center
    text-white no-underline
}
.Row_lhs{
    @apply w-1/2 flex gap-2 items-center
}
.Row_rhs{
    @apply w-1/2 flex justify-end items-center
}

.Row_lhs img{
    @apply h-12 rounded-full
}


.smallText{
    @apply text-xs opacity-50 font-semibold my-0
}
.Row_lhs_metadata{
 @apply h-fit flex flex-col justify-center
 gap-2
}
.Row_lhs_metadata .title{
    @apply my-0
}
.Row_rhs .followers{
    @apply text-sm font-medium opacity-70
}