#HeroSection{
    @apply w-full h-screen grid;
    grid-template-columns: 1fr 1fr;
    overflow-x: auto

}

#HeroLhs, #HeroRhs{
    @apply 
    flex 
    justify-center items-center
}
#HeroLhs{
@apply text-white ;
}

#HeroRhs{
  @apply gap-2 flex-wrap;
}
.HeroLhs_metadata .title{
 @apply text-5xl my-4
}
.HeroLhs_metadata .subtitle{
    @apply text-base my-2
}


@media only screen and (max-width: 1780px) {
 #HeroSection{
    @apply grid;
    grid-template-columns:  1fr;
    grid-template-rows: 12rem 1fr;
 }
#HeroRhs{
    @apply flex justify-center items-start
}
}

@media only screen and (max-width: 800px) {

        #HeroSection{
            @apply grid;
            grid-template-rows:12rem 1fr;
         }
}