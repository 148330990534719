body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background:  linear-gradient(135deg, rgb(245, 248, 255) 0%, rgb(234, 244, 255) 100%); */

  /* background:  linear-gradient(135deg, rgb(245, 248, 255) 0%, rgb(234, 244, 255) 100%);
 */

 background-image: url(../public/bg.png);
 background-repeat: no-repeat;
 background-size: 100% 100%;

 @apply h-screen w-full;
  
}

@media only screen and (max-width: 940px) {
  html{
      font-size: 13.5px;
  }
}