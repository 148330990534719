.card{
    height: fit-content;
    width: 24rem;
    background-color: #464646;
    color: #fff;
    @apply rounded-2xl p-4;
}
.card:nth-last-of-type(1)
{
    background-color: #5f5f5f;
    color: #fff;
    position: relative;
    top: 2rem;
    @apply mb-5;
}

.cardTitle{
    background-color: #2e2e2e;
    color: #fff;
    width: fit-content;
    @apply px-3 py-0.5 font-medium text-sm rounded-full
}
.card:nth-last-of-type(1) .cardTitle{
    background-color: #2e2e2e;
    color: #fff;
}



@media only screen and (max-width: 940px) {
  
 .card{
width: 80w;
 }
}